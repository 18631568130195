// @flow

import React from 'react'

import { getSelectedLanguage } from 'platform_web/utility/localizationUtil'
import useModal from 'care-ui/molecules/hooks/useModal'

const useDownloadReport = () => {
  const { isOpen, closeModal, openModal } = useModal()
  const languageSwitcherLng = getSelectedLanguage?.value || 'en'

  const [selectedLng, setSelectedLng] = React.useState<string>(
    languageSwitcherLng,
  )

  return {
    isOpen,
    openModal,
    closeModal,
    selectedLng,
    setSelectedLng,
    languageSwitcherLng,
  }
}

export default useDownloadReport
