// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import ResultsDownloadModal from 'platform_web/components/ResultsDownloadModal/ResultsDownloadModal'
import useDownloadReport from 'platform_web/hooks/useDownloadReport'

import IndividualTasksItem from './IndividualTasksItem'

import type { StaffDownloadDataTask_individual } from './__generated__/StaffDownloadDataTask_individual.graphql'

type PropsType = {
  individual: StaffDownloadDataTask_individual,
}
const StaffDownloadDataTaskComponent = ({ individual }: PropsType) => {
  const { t: translation } = useTranslation()
  const { user } = individual
  const {
    isOpen,
    openModal,
    closeModal,
    selectedLng,
    setSelectedLng,
  } = useDownloadReport()

  const handleClick = () => {
    const newWindow = window.open(
      `/individual/emr?user_id=${user.id}&locale=${selectedLng}`,
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
    closeModal()
  }

  return (
    <>
      <IndividualTasksItem
        header={translation('health_history_pdf')}
        onClick={openModal}
      >
        {translation('download_a_pdf_of_their_health_history')}
      </IndividualTasksItem>
      <ResultsDownloadModal
        setSelectedLng={setSelectedLng}
        closeModal={closeModal}
        isOpen={isOpen}
        onChange={handleClick}
      />
    </>
  )
}

export default createFragmentContainer(StaffDownloadDataTaskComponent, {
  individual: graphql`
    fragment StaffDownloadDataTask_individual on IndividualRole {
      user {
        id
      }
    }
  `,
})
