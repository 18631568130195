// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { FlexContainer } from 'care-ui'

import TableSearchControl from '../components/TableSearchControl/TableSearchControl'
import TableSortControl from '../components/TableSortControl/TableSortControl'
import { type UseCliniciansTableReturnType } from '../hooks/useCliniciansTable'

type PartialIndividualTableReturnType = $Shape<UseCliniciansTableReturnType>

type CliniciansTableControlsProps = {
  useCliniciansTablePayload: PartialIndividualTableReturnType,
}

const CliniciansTableControl = (props: CliniciansTableControlsProps) => {
  const { t: translation } = useTranslation()
  const SORT_OPTIONS = [
    { header: 'name', label: translation('clinician_name') },
    { header: 'individualAssignmentCount', label: translation('assigned') },
  ]

  const { useCliniciansTablePayload } = props
  const { queryVariables, updateQueryVariables } = useCliniciansTablePayload

  return (
    <FlexContainer justifyContent="space-between" paddingX="xxs">
      <TableSortControl
        sortOptions={SORT_OPTIONS}
        queryVariables={queryVariables}
        updateQueryVariables={updateQueryVariables}
      />

      <TableSearchControl
        queryVariables={queryVariables}
        updateQueryVariables={updateQueryVariables}
      />
    </FlexContainer>
  )
}

export default CliniciansTableControl
