// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import ResultsDownloadModal from 'platform_web/components/ResultsDownloadModal/ResultsDownloadModal'
import useDownloadReport from 'platform_web/hooks/useDownloadReport'

import IndividualTasksItem from './IndividualTasksItem'

export default () => {
  const { t: translation } = useTranslation()
  const {
    isOpen,
    openModal,
    closeModal,
    selectedLng,
    setSelectedLng,
  } = useDownloadReport()
  const handleClick = () => {
    const newWindow = window.open(
      `/individual/emr?locale=${selectedLng}`,
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
    closeModal()
  }

  return (
    <>
      <IndividualTasksItem
        header={translation('download_health_history_pdf')}
        onClick={openModal}
      >
        {translation('its_your_history_and_you_can_take_it_with_you')}
      </IndividualTasksItem>
      <ResultsDownloadModal
        setSelectedLng={setSelectedLng}
        closeModal={closeModal}
        isOpen={isOpen}
        onChange={handleClick}
      />
    </>
  )
}
