// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import Section from 'react-ui/components/Section'
import ResultsDownloadModal from 'platform_web/components/ResultsDownloadModal/ResultsDownloadModal'
import useDownloadReport from 'platform_web/hooks/useDownloadReport'
import { Button } from 'care-ui'

import { staffDownloadReportButtonIndividual } from './query/StaffDownloadReportButton'
import { buttonContainerRules } from './StaffDownloadReportButton.style'

import type { StaffDownloadReportButton_individual } from './query/__generated__/StaffDownloadReportButton_individual.graphql'

type PropsType = {
  individual: StaffDownloadReportButton_individual,
}

const StaffDownloadReportButton = (props: PropsType) => {
  const { individual } = props
  const { t: translation } = useTranslation()
  const { user } = individual

  const {
    isOpen,
    openModal,
    closeModal,
    selectedLng,
    setSelectedLng,
  } = useDownloadReport()

  const handleClick = () => {
    const newWindow = window.open(
      `/individual/emr?user_id=${user.id}&locale=${selectedLng}`,
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
    closeModal()
  }

  return (
    <>
      <Section extend={buttonContainerRules} noPadding color="white">
        <Button
          onClick={openModal}
          variant="primary"
          ariaLabel={translation('report')}
          dataTestId="report"
        >
          {translation('report')}
        </Button>
      </Section>
      <ResultsDownloadModal
        setSelectedLng={setSelectedLng}
        closeModal={closeModal}
        isOpen={isOpen}
        onChange={handleClick}
      />
    </>
  )
}

export default createFragmentContainer(
  StaffDownloadReportButton,
  staffDownloadReportButtonIndividual,
)
