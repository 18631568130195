// @flow

import React, { type ComponentType, type Node } from 'react'
import { connect } from 'react-fela'

import escalatedIcon from 'react-ui/assets/icons/Icon material-warning.svg'
import doWithClinician from 'react-ui/assets/icons/left-flag.svg'
import helpIcon from 'react-ui/assets/icons/speech-bubble.svg'
import Avatar from 'react-ui/components/Avatar'
import type { AvatarPropTypes } from 'react-ui/components/Avatar/Avatar'
import Icon from 'react-ui/components/Icon'
import { withoutFelaProps } from 'shared/services/fela'
import { Button, Heading, Text } from 'care-ui'

import type { FelaOptionalPropsType, FelaPropsType } from 'react-ui/typing'
import type { UserCardLoader_user } from './__generated__/UserCardLoader_user.graphql'

const styleRules = ({ size = 'medium', theme }) => ({
  UserCard: {
    alignItems: 'flex-start',
    className: 'UserCard',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    paddingBottom: 0,
    paddingTop: theme.spacing(0.25),
  },
  contentContainer: {
    boxSizing: 'border-box',
    className: 'UserCard__contentContainer',
    flex: '0 1 100%',
    '-ms-flex': '0 1 auto',
    flexFlow: 'column wrap',
    maxWidth: '100%',
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
  },
  flagContainer: {
    boxSizing: 'border-box',
    className: 'UserCard__flagContainer',
    maxWidth: '100%',
    padding: `calc(${theme.Grid.gutter}/6) 0 0 calc(${theme.Grid.gutter})`,
  },
  avatar: {
    className: `UserCard__avatar--${size}`,
    marginTop: `-${theme.spacing(0.25)}`,
    ...(() => {
      if (size === 'large') {
        return {
          height: theme.spacing(2),
          width: theme.spacing(2),
        }
      }
      return {}
    })(),
  },
  name: {
    className: `UserCard__name--${size}`,
    overflow: 'hidden',
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  preferredName: {
    className: 'UserCard__preferredName',
  },
  children: {
    className: 'UserCard__children',
    display: 'block',
  },
  escalationIcon: {
    marginRight: '15px',
  },
  withStaffFlag: {
    height: '1.25rem',
    width: 'unset',
    fill: 'unset',
  },
  withCliFlag: {
    height: '13px',
    width: '9px',
    color: theme.palette.reports.chartColors[4],
  },
})

export type UserCardProps = AvatarPropTypes & {
  children?: Node,
  clinHelpQuestionnaire?: boolean,
  escalated?: boolean,
  link?: boolean,
  noAvatar?: boolean,
  showFlag?: boolean,
  size?: 'medium' | 'large',
  staff?: boolean,
  user: UserCardLoader_user,
}

const UserCard = ({
  as,
  children,
  escalated = false,
  link = true,
  showFlag = false,
  staff = false,
  size = 'medium',
  rules,
  styles,
  noAvatar = false,
  clinHelpQuestionnaire,
  user,
  user: { username, legal_name, preferred_name },
  ...props
}: UserCardProps & FelaPropsType) => {
  const Component: string = (as: any) || 'div'

  const displayName = legal_name || username

  return (
    <Component {...withoutFelaProps(props)} className={styles.UserCard}>
      {!staff && !noAvatar && (
        <Avatar
          user={user}
          extend={(...args) => ({
            Avatar: rules.avatar(...args),
          })}
        />
      )}
      {!staff && showFlag && clinHelpQuestionnaire && (
        <div className={styles.flagContainer}>
          <Icon as={doWithClinician} className={styles.withCliFlag} />
        </div>
      )}
      <div className={styles.contentContainer}>
        {link && (
          <Button
            to={`/users/${user.id}/dashboard`}
            extend={(...args) => rules.name(...args)}
            variant="link"
            dataTestId="patientName"
          >
            {displayName}
          </Button>
        )}
        {!link && size === 'medium' && (
          <Text size="lg" className={styles.name} dataTestId="patientName">
            {displayName}
          </Text>
        )}
        {!link && size === 'large' && (
          <Heading level={4} className={styles.name}>
            {displayName}
          </Heading>
        )}
        {!staff && (
          <Text size="lg" bold>
            {preferred_name && `"${preferred_name}"`}
          </Text>
        )}
        {!staff && <div className={styles.children}>{children}</div>}

        <div>
          {staff && escalated && (
            <span className={styles.escalationIcon}>
              <Icon
                color="hsla"
                as={escalatedIcon}
                className={styles.withStaffFlag}
              />
            </span>
          )}

          {staff && showFlag && clinHelpQuestionnaire && (
            <span>
              <Icon as={helpIcon} className={styles.withStaffFlag} />
            </span>
          )}
        </div>
      </div>
    </Component>
  )
}

const StyledComponent: ComponentType<
  UserCardProps & FelaOptionalPropsType,
> = connect(styleRules)(UserCard)

export default StyledComponent
