// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Section from 'react-ui/components/Section'
import ResultsDownloadModal from 'platform_web/components/ResultsDownloadModal/ResultsDownloadModal'
import useChartToggle from 'platform_web/hooks/useChartToggle'
import useDownloadReport from 'platform_web/hooks/useDownloadReport'
import { Button } from 'care-ui'

import { buttonContainerRules } from './DownloadReportTaskButton.style'

const DownloadReportTaskButton = () => {
  const { isSummaryGraph } = useChartToggle()
  const { t: translation } = useTranslation()
  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])
  const {
    isOpen,
    openModal,
    closeModal,
    selectedLng,
    setSelectedLng,
  } = useDownloadReport()

  if (!isSummaryGraph || !isIndividual) return null

  const handleClick = () => {
    const newWindow = window.open(
      `/individual/emr?locale=${selectedLng}`,
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
    closeModal()
  }

  return (
    <Section extend={buttonContainerRules} noPadding>
      <Button
        onClick={openModal}
        variant="primary"
        ariaLabel={translation('report')}
        dataTestId="report"
      >
        {translation('report')}
      </Button>
      <ResultsDownloadModal
        setSelectedLng={setSelectedLng}
        closeModal={closeModal}
        isOpen={isOpen}
        onChange={handleClick}
      />
    </Section>
  )
}

export default DownloadReportTaskButton
