// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'

import InviteModal from 'components/Role/RoleInviteModal'
import { UserInviteFormLoader } from 'components/UserInviteForm/UserInviteForm'

import type { IndividualsAdminInvite_current_role } from './__generated__/IndividualsAdminInvite_current_role.graphql'

type PropsType = {
  current_role: IndividualsAdminInvite_current_role | any,
}

const emptyInvitableUser = (tenant?: ?{ +id: string }) => ({
  email: '',
  phone_number: '',
  roleType: 'INDIVIDUAL',
  tenantId: tenant?.id,
  clinicianAssignmentId: '',
  emrUserId: '',
})

const IndividualsAdminInvite = ({ current_role }: PropsType) => {
  const environment = useRelayEnvironment()

  // Localization
  const { t: translation } = useTranslation()

  if (!current_role || !current_role.tenant) {
    return null
  }

  const { tenant, tenants } = current_role

  return (
    <InviteModal
      heading={translation('invite_individual')}
      title={translation('invite_individual')}
      queryType="individual"
    >
      {({ onFormSubmit }) => (
        <UserInviteFormLoader
          id="individualInviteForm"
          invitableUser={emptyInvitableUser(tenant)}
          hideMobile={tenant?.hide_mobile_on_invite}
          tenants={tenants}
          onSubmit={onFormSubmit(environment)}
          translation={translation}
        />
      )}
    </InviteModal>
  )
}

export const IndividualsAdminInviteLoader = createFragmentContainer(
  IndividualsAdminInvite,
  {
    current_role: graphql`
      fragment IndividualsAdminInvite_current_role on RoleInterface {
        tenant {
          hide_mobile_on_invite
          id
        }
        tenants {
          ...UserInviteForm_tenants
        }
      }
    `,
  },
)
