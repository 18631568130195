// @flow

import React, { type Node } from 'react'

import { TrackableChartToggle } from 'components/SummaryGraph'
import HamburgerIcon from 'react-ui/assets/icons/hamburger.svg'
import Logo from 'react-ui/assets/images/innowell-logo-202111.svg'
import Icon from 'react-ui/components/Icon'
import { OptionalLink } from 'react-ui/components/Link'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import LanguageSwitcher from 'platform_web/components/LanguageSwitcher/LanguageSwitcher'
import { Box, Container, FlexContainer } from 'care-ui'

import IconButton from './IconButton'
import NavigationContext from './Navigation/NavigationContext'

type PropsType = {
  actions?: Node,
  as?: string,
  componentId?: string,
  hideLogoHeader: boolean,
  menuIsOpen?: boolean,
  navId: string,
  noMenu?: boolean,
  onToggleMenu: () => void,
  triggerId: string,
}

const PageHeader = (props: PropsType) => {
  const {
    actions,
    hideLogoHeader,
    menuIsOpen,
    onToggleMenu,
    navId,
    triggerId,
  } = props

  const { homepage_path } = useCurrentUser()

  const hamBurgerMenu = () => {
    return (
      <NavigationContext.Consumer>
        {({ menu }) => {
          const variableAriaAttributes = menuIsOpen
            ? {
                'aria-controls': navId,
                'aria-owns': navId,
              }
            : {}
          return (
            <React.Fragment>
              {!!menu &&
                !!React.Children.toArray(menu).filter((child) => !!child)
                  .length && (
                  <div>
                    <IconButton
                      onClick={(evt) => {
                        evt.preventDefault()
                        onToggleMenu()
                      }}
                      innerRef={(el) => {
                        if (el && menuIsOpen) {
                          el.blur()
                        }
                      }}
                      aria-expanded={menuIsOpen ? 'true' : 'false'}
                      aria-haspopup="true"
                      id={triggerId}
                      {...variableAriaAttributes}
                    >
                      <Icon as={HamburgerIcon} title="Navigation" />
                    </IconButton>
                  </div>
                )}
            </React.Fragment>
          )
        }}
      </NavigationContext.Consumer>
    )
  }

  return (
    <Box
      width="100%"
      height="63px"
      zIndex={20}
      position="fixed"
      display="block"
      top={0}
      backgroundColor="white"
      borderBottom="subtle"
    >
      <FlexContainer wrap="nowrap" alignItems="center" height="100%">
        <Container>
          <FlexContainer
            wrap="nowrap"
            alignItems="center"
            height="100%"
            justifyContent="space-between"
          >
            <FlexContainer alignItems="center">
              {!hideLogoHeader && hamBurgerMenu()}

              <OptionalLink link={homepage_path}>
                <Logo width="140px" height="30px" />
              </OptionalLink>
            </FlexContainer>

            <TrackableChartToggle />

            <FlexContainer gap="xs">
              <LanguageSwitcher />
              {actions}
            </FlexContainer>
          </FlexContainer>
        </Container>
      </FlexContainer>
    </Box>
  )
}

export default PageHeader
