// @flow

import i18n from 'platform_web/localization/index'

// TODO: Remove this check once the translations are finalised and added to the platform
const forbiddenUrls = ['au.platform.innowell.org', 'ca.platform.innowell.org']

export const isForbiddenUrl = forbiddenUrls.includes(window.location.hostname)

export const LANGUAGES = [
  { value: 'en', label: 'English (Australia)' },
  { value: 'fr', label: 'Français (Canada)' },
]

export const getSelectedLanguage = LANGUAGES.find(
  (language) => language.value === i18n.language,
)
