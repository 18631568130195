// @flow

import {
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns/esm/fp'
import { flow, identity, map, sortBy } from 'lodash/fp'

import { localizedFormat } from 'shared/utils/DateFormatUtils'

export type TimeIntervalType =
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'year'

// date-fns date format changed from 'DD/MM/YYYY' to 'dd/MM/yyyy' since 2.0.0-alpha.8
// https://date-fns.org/v2.0.0-alpha.10/docs/format
export const shortDate = 'dd/MM/yyyy'
const longDate = 'dd MMMM yyyy'
const longDayWeekName = 'EEEE, d LLL'
const shortMonthDay = 'MMM do'
const shortTime = 'h:mm a'
const longTime = 'h:mm:ss a'
const dateTime = `${shortDate} ${shortTime}`
const shortDayTime = `${shortMonthDay} ${shortTime}`
const longDateTime = `${longDate} | ${shortTime}`

type OptionsType = {
  count: number,
  date: Date,
  interval: TimeIntervalType,
}

type DatelikeType = string | number | Date

const SECONDS_PER_HOUR = 60 * 60
export const MILLIS_PER_HOUR = SECONDS_PER_HOUR * 1000
export const MILLIS_PER_DAY = MILLIS_PER_HOUR * 24

export const asDate = (date: DatelikeType) => {
  if (typeof date === 'string') return new Date(date)
  if (typeof date === 'number') return new Date(date)
  if (date instanceof Date) return new Date(date.getTime())
  throw new Error('date should be string or number or Date type')
}

const intervalFunction: { [key: TimeIntervalType]: Function } = {
  minute: subMinutes,
  day: subDays,
  week: subWeeks,
  hour: subHours,
  month: subMonths,
  year: subYears,
}

export const longDateFormat = (date: DatelikeType) =>
  localizedFormat(date, longDate)

export const longDayWeekNameFormat = (date: DatelikeType) =>
  localizedFormat(date, longDayWeekName)

export const shortMonthDayFormat = (date: DatelikeType) =>
  localizedFormat(date, shortMonthDay)

export const shortDayTimeFormat = (date: DatelikeType) =>
  localizedFormat(date, shortDayTime)

export const shortDateFormat = (date: DatelikeType) =>
  localizedFormat(date, shortDate)

export const longTimeFormat = (date: DatelikeType) =>
  localizedFormat(date, longTime)

export const shortTimeFormat = (date: DatelikeType) =>
  localizedFormat(date, shortTime)

export const dateTimeFormat = (date: DatelikeType) =>
  localizedFormat(date, dateTime)

export const longDateTimeFormat = (date: DatelikeType) =>
  localizedFormat(date, longDateTime)

export const generateDateTimes = ({
  interval,
  count,
  date,
}: OptionsType): Array<string> =>
  flow(
    map.convert({ cap: false })((_, n: number): Date =>
      intervalFunction[interval](n)(date),
    ),
    sortBy(identity),
  )(new Array(count))

export const ago = (count: number, interval: TimeIntervalType, date?: Date) =>
  intervalFunction[interval](count)(date || new Date())

export const dateDifferenceAsNumber = (
  firstDate: DatelikeType,
  secondDate: DatelikeType,
): number => asDate(firstDate) - asDate(secondDate)

export const isPast = (date: DatelikeType): boolean =>
  dateDifferenceAsNumber(date, new Date()) < 0

export const isFuture = (date: DatelikeType): boolean =>
  dateDifferenceAsNumber(date, new Date()) > 0

export const isNow = (date: DatelikeType): boolean =>
  dateDifferenceAsNumber(date, new Date()) === 0

const hasZone = /(Z|[+-][\d:]+)$/
/**
 * Parse an ISO 8601 string into a date, defaulting to UTC if no zone information
 * is found in the string.
 */
export const asUTC = (dateString: string) => {
  if (hasZone.test(dateString)) return new Date(dateString)
  return new Date(`${dateString}Z`)
}

export const utcStartOfDay = (date: DatelikeType): Date => {
  const utcDate = asDate(date)
  utcDate.setUTCHours(0, 0, 0, 0)
  return utcDate
}

/**
 * Convert a user-local datetime into an equivalent Date in UTC, with the same
 * date and time values.
 * e.g. input: new Date('2019-08-06T09:46:33+10:00')
 *     output: new Date('2019-08-06T09:46:33Z')
 */
export const localToUtc = (date: DatelikeType): Date => {
  const localDate = asDate(date)
  return new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds(),
      localDate.getMilliseconds(),
    ),
  )
}

export const toISO = (date: DatelikeType): string => asDate(date).toISOString()
