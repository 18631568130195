/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type IndividualsTable_individual_mutation$ref = any;
export type UserInviteInput = {|
  clientMutationId?: ?string,
  clinicianAssignmentId?: ?string,
  email?: ?string,
  emrUserId?: ?string,
  phone_number?: ?string,
  roleType: string,
  tenantGroupId?: ?string,
  tenantId?: ?string,
|};
export type RoleIndividualInviteMutationVariables = {|
  input: UserInviteInput
|};
export type RoleIndividualInviteMutationResponse = {|
  +userInvite: ?{|
    +role: {|
      +$fragmentRefs: IndividualsTable_individual_mutation$ref
    |}
  |}
|};
export type RoleIndividualInviteMutation = {|
  variables: RoleIndividualInviteMutationVariables,
  response: RoleIndividualInviteMutationResponse,
|};
*/


/*
mutation RoleIndividualInviteMutation(
  $input: UserInviteInput!
) {
  userInvite(input: $input) {
    role {
      __typename
      ... on IndividualRole {
        ...IndividualsTable_individual_mutation
        id
      }
      ... on AdminRole {
        id
      }
      ... on ClinicianRole {
        id
      }
      ... on LeadClinicianRole {
        id
      }
      ... on ManagerRole {
        id
      }
      ... on OwnerRole {
        id
      }
      ... on ResearcherRole {
        id
      }
      ... on SuperRole {
        id
      }
      ... on SupportPersonRole {
        id
      }
    }
  }
}

fragment IndividualsTable_individual_mutation on IndividualRole {
  id
  role_status
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleIndividualInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserInvitePayload",
        "kind": "LinkedField",
        "name": "userInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "IndividualsTable_individual_mutation"
                  }
                ],
                "type": "IndividualRole",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleIndividualInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserInvitePayload",
        "kind": "LinkedField",
        "name": "userInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role_status",
                    "storageKey": null
                  }
                ],
                "type": "IndividualRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "AdminRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "ClinicianRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "LeadClinicianRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "ManagerRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "OwnerRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "ResearcherRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "SuperRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "SupportPersonRole",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e54a8d6b0a92f6aabe0f6cef8a8557e8",
    "id": null,
    "metadata": {},
    "name": "RoleIndividualInviteMutation",
    "operationKind": "mutation",
    "text": "mutation RoleIndividualInviteMutation(\n  $input: UserInviteInput!\n) {\n  userInvite(input: $input) {\n    role {\n      __typename\n      ... on IndividualRole {\n        ...IndividualsTable_individual_mutation\n        id\n      }\n      ... on AdminRole {\n        id\n      }\n      ... on ClinicianRole {\n        id\n      }\n      ... on LeadClinicianRole {\n        id\n      }\n      ... on ManagerRole {\n        id\n      }\n      ... on OwnerRole {\n        id\n      }\n      ... on ResearcherRole {\n        id\n      }\n      ... on SuperRole {\n        id\n      }\n      ... on SupportPersonRole {\n        id\n      }\n    }\n  }\n}\n\nfragment IndividualsTable_individual_mutation on IndividualRole {\n  id\n  role_status\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c7dd317f37ee35b5f7a747992473acf';

module.exports = node;
