// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import LanguageSwitcher from 'platform_web/components/LanguageSwitcher/LanguageSwitcher'
import { isForbiddenUrl } from 'platform_web/utility/localizationUtil'
import { Button, FlexContainer, FlexItem, Modal, Text } from 'care-ui'

type PropsType = {
  closeModal: () => void,
  isOpen: boolean,
  onChange: () => void,
  setSelectedLng: (((string) => string) | string) => void,
}

const ResultsDownloadModal = (props: PropsType) => {
  const { closeModal, isOpen, onChange, setSelectedLng } = props
  const { t: translation } = useTranslation()

  return (
    <Modal
      width="560px"
      closeModal={closeModal}
      heading={translation('results_download')}
      isOpen={isOpen}
    >
      <FlexContainer
        height="200px"
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        gap="sm"
      >
        <Text as="p">
          {translation(
            'please_ensure_you_take_appropriate_steps_to_protect_the_privacy_of_this_document_in_accordance_with_the_clinical_governance_requirements_of_your_service',
          )}
        </Text>
        {!isForbiddenUrl && (
          <FlexContainer
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            gap="lg"
          >
            <Text as="p">{translation('select_export_language')}</Text>
            <FlexItem>
              <LanguageSwitcher handleLanguageChange={setSelectedLng} />
            </FlexItem>
          </FlexContainer>
        )}
        <Button
          dataTestId="emrDownloadButton"
          onClick={onChange}
          variant="primary"
          ariaLabel={translation('export')}
        >
          {translation('export')}
        </Button>
      </FlexContainer>
    </Modal>
  )
}

export default ResultsDownloadModal
