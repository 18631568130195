// @flow

import format from 'date-fns/format'
import { formatDistanceWithOptions } from 'date-fns/fp'
import { enUS, fr } from 'date-fns/locale'

import { asDate } from 'services/dateTime'
import i18n from 'platform_web/localization/index'

type DatelikeType = string | number | Date

export const getdateFnsLocale = () => {
  return i18n.language === 'fr' ? fr : enUS
}

export const formatDistance = () => {
  const locale = getdateFnsLocale()
  return formatDistanceWithOptions({ locale })
}

export const localizedFormat = (date: DatelikeType, pattern: string) => {
  const locale = getdateFnsLocale()
  return format(asDate(date), pattern, { locale })
}
