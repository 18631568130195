// @flow

import React, { type Node, useEffect, useState } from 'react'
import { connect } from 'react-fela'
import { Portal } from 'react-portal'
import { useRouter } from 'found'

import { withoutFelaProps } from 'shared/services/fela'

import Navigation from './Navigation/Navigation'
import PageHeader from './PageHeader'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = () => ({
  Page: {
    boxSizing: 'border-box',
    className: 'Page',
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
  },
  content: {
    marginTop: '63px',
    className: 'Page__content',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'column',
    justifyContent: 'stretch',
    position: 'relative',
    width: 'inherit',
  },
})

export type PropsType = FelaPropsType & {
  actions?: Node,
  as?: string,
  children: Node,
  noMenu?: boolean,
}

const Page = ({
  actions,
  as: Component = 'div',
  children,
  noMenu,
  styles,
  ...props
}: PropsType) => {
  const [menuIsOpen, setMenu] = useState(false)
  const { match } = useRouter() || {}

  const { location } = match || {}
  const { pathname } = location || {}

  const hideLogoHeader =
    (pathname && pathname.includes('questionnaires_question_sets')) || false

  // Flow assumes there might be a document without body
  const { body } = document

  useEffect(() => {
    if (body) body.style.overflow = menuIsOpen ? 'hidden' : 'unset'
  }, [menuIsOpen])

  const menuHandler = () => {
    setMenu(!menuIsOpen)
  }
  const navId = 'primary-navigation'
  const triggerId = 'primary-navigation-trigger'

  return (
    <Component className={styles.Page} {...withoutFelaProps(props)}>
      {menuIsOpen && (
        <Navigation
          id={navId}
          onRequestClose={menuHandler}
          role="group"
          aria-labelledby={triggerId}
        />
      )}
      <Portal>
        <PageHeader
          hideLogoHeader={hideLogoHeader}
          actions={actions}
          key="visible-page-header"
          noMenu={noMenu}
          onToggleMenu={menuHandler}
          navId={navId}
          triggerId={triggerId}
        />
      </Portal>
      <div className={styles.content}>{children}</div>
    </Component>
  )
}

export default connect(styleRules)(Page)
