// @flow

import * as React from 'react'

import HelpNowButton from 'platform_web/features/Header/HelpNowButton'

const AuthActions = () => {
  return <HelpNowButton />
}

export default AuthActions
