// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { upperCase } from 'lodash'
import { flatMap } from 'lodash/fp'

import { useAssessmentRequesteeContext } from 'platform_web/features/AssessmentRequests'
import { buildLocalizationKey } from 'platform_web/utility/buildLocalizationKey'
import {
  Box,
  Button,
  DropDownMenu,
  FlexContainer,
  Heading,
  Icon,
} from 'care-ui'
import ArrowDown from 'care-ui/atoms/icons/arrow-down.svg'

import AssessmentRequesteesListItem from '../AssessmentRequesteesListItem'

import { fragment } from './query/AssessmentRequesteesList'

import type { AssessmentRequesteesList_individual$key } from './query/__generated__/AssessmentRequesteesList_individual.graphql'

type AssessmentRequesteesListProps = {
  individual: ?AssessmentRequesteesList_individual$key,
  requesteeRoleId: ?string,
  setRequesteeRoleId: string => void,
}

const AssessmentRequesteesList = (props: AssessmentRequesteesListProps) => {
  const { individual: fragRef } = props
  const individual = useFragment(fragment, fragRef)
  const {
    setAssessmentRequesteeId,
    assessmentRequesteeId,
    setPreSelected,
  } = useAssessmentRequesteeContext()

  const [closeDropDown, setCloseDropDown] = useState(true)

  const { id: individualId, legal_name, support_person_assignments } =
    individual || {}

  const activeSupportPersons = flatMap(assignment => {
    return assignment.accepted_invitation
      ? { ...assignment.support_person, relationship: assignment.relationship }
      : []
  })(support_person_assignments)

  const { t: translation } = useTranslation()

  if (activeSupportPersons.length === 0) return null

  const allOptions = [{ id: individualId, legal_name }, ...activeSupportPersons]

  const selectedOption = allOptions.find(
    option => option.id === assessmentRequesteeId,
  )

  const selectedOptionRelationship = selectedOption?.relationship
    ? upperCase(translation(buildLocalizationKey(selectedOption?.relationship)))
    : upperCase(translation('individual'))

  const handleClick = () => {
    setPreSelected(null)
    setAssessmentRequesteeId(individualId)
    setCloseDropDown(true)
  }

  const handleSupportClick = requesteeId => {
    setPreSelected(null)
    setAssessmentRequesteeId(requesteeId)
    setCloseDropDown(true)
  }

  const toggleDropDownMenu = () => {
    setCloseDropDown(!closeDropDown)
  }

  return (
    <Box width="100%">
      <div style={{ marginBottom: '8px' }}>
        <Heading level={6}>
          {translation('select_person_to_send_a_request_to')}
        </Heading>
      </div>

      <DropDownMenu
        fullWidth
        closeDropDown={closeDropDown}
        setCloseDropDown={setCloseDropDown}
        dropDownTrigger={
          <Button
            variant="secondary"
            onClick={toggleDropDownMenu}
            dataTestId="drop-down-trigger"
            fullWidth
          >
            <Box width="100%">
              <FlexContainer
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <AssessmentRequesteesListItem
                  title={
                    selectedOption?.legal_name || translation('select_a_person')
                  }
                  subtitle={selectedOptionRelationship}
                  listItem={false}
                />
                <Icon as={ArrowDown} scale={0.5} />
              </FlexContainer>
            </Box>
          </Button>
        }
      >
        <>
          {/* i18n -> The rest of the list should be translated */}
          <AssessmentRequesteesListItem
            title={legal_name || translation('individual')}
            subtitle={upperCase(translation('individual'))}
            onClick={handleClick}
          />

          {activeSupportPersons.map(activeSupportPerson => (
            <AssessmentRequesteesListItem
              title={activeSupportPerson.legal_name}
              subtitle={upperCase(
                translation(
                  buildLocalizationKey(activeSupportPerson.relationship),
                ),
              )}
              onClick={() => handleSupportClick(activeSupportPerson.id)}
            />
          ))}
        </>
      </DropDownMenu>
    </Box>
  )
}

export default AssessmentRequesteesList
