// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import ReadMore from 'react-ui/components/ReadMore'
import { dateTimeFormat } from 'services/dateTime'

export type PropsType = {
  reason: {
    resolution_option: string,
    resolution_text: string,
    resolved_at: string,
  },
}

const EscalationReasonCard = ({
  reason: { resolution_option, resolution_text, resolved_at },
}: PropsType) => {
  const { t: translation } = useTranslation()

  if (resolution_option !== 'other') {
    return translation(resolution_option)
  }

  return (
    <ReadMore
      heading={`${translation('time')}: ${dateTimeFormat(resolved_at)}`}
      subHeading={`${translation('other')}:`}
      text={resolution_text}
      characterLimit={20}
    />
  )
}

export default EscalationReasonCard
