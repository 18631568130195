// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { useRouter } from 'found'
import { uniqueId } from 'lodash'

import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'
import { Heading, Text } from 'care-ui'

import HealthCard from '../../HealthCard'

import {
  cardContainerStyle,
  containerStyle,
  headerStyle,
  linkStyle,
  subHeaderStyle,
} from './HealthCardList.style'
import { healthCardListFragment } from './query/HealthCardList'

import type { HealthCardList_user_trackables } from './query/__generated__/HealthCardList_user_trackables.graphql'

type PropsType = {
  +user_trackables: HealthCardList_user_trackables,
}

const HealthCardList = (props: PropsType) => {
  const { user_trackables: fragmentRef } = props
  const user_trackables = useFragment(healthCardListFragment, fragmentRef)

  const { router, match } = useRouter()
  const { params } = match
  const { id } = params

  const { css } = useFela()

  const { roleType } = useCurrentUserRoles()

  const isNotIndividual = roleType !== 'INDIVIDUAL'

  const onLinkClick = () => {
    router.push({
      name: 'user_assessment_requests',
      params: { id },
      query: { tab: '1' },
    })
  }

  const { t: translation } = useTranslation()

  const healthCards = user_trackables?.map((user_trackable) => {
    return (
      <HealthCard
        key={uniqueId('health-card-')}
        user_trackable={user_trackable}
      />
    )
  })

  return (
    <div className={css(containerStyle)} data-testid="latestResultSection">
      <div className={css(headerStyle)}>
        <div className={css(subHeaderStyle)}>
          <Heading level={2}>{translation('latest_results')}</Heading>

          {isNotIndividual && (
            <div
              className={css(linkStyle)}
              role="button"
              tabIndex="0"
              onKeyPress={onLinkClick}
              onClick={onLinkClick}
            >
              {translation('view_assessment_library')}
            </div>
          )}
        </div>

        <Text>
          {translation(
            'click_on_a_health_card_to_find_fact_sheets_browse_a_number_of_care_options_and_review_your_results',
          )}
        </Text>
      </div>

      <div className={css(cardContainerStyle)}>{healthCards}</div>
    </div>
  )
}

export default HealthCardList
