/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unused-modules */

// @flow

import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import getQueryParams from 'platform_web/utility/getQueryParams'

import resources from './resources'

type QueryParams = {
  [key: string]: string,
}

// TODO: Remove this check once the translations are finalised and added to the platform
const forbiddenUrls = ['au.platform.innowell.org', 'ca.platform.innowell.org']

const queryParams: QueryParams = getQueryParams()
const { lng } = queryParams

if (lng) {
  localStorage.setItem('language', lng)
}

const url = window.location.hostname
const selectedLanguage = localStorage.getItem('language') || 'en'

const i18nConfig = {
  resources,
  fallbackLng: 'en',
  // lng,
  // Maybe check if the url is a prod url, use english no matter what they add for the e.g. lng=fr
  lng: forbiddenUrls.includes(url) ? 'en' : selectedLanguage,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
}

i18n.use(initReactI18next).init(i18nConfig)

export default i18n
