/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EmployeesTable_staff_mutation$ref = any;
export type UserInviteInput = {|
  clientMutationId?: ?string,
  clinicianAssignmentId?: ?string,
  email?: ?string,
  emrUserId?: ?string,
  phone_number?: ?string,
  roleType: string,
  tenantGroupId?: ?string,
  tenantId?: ?string,
|};
export type RoleStaffInviteMutationVariables = {|
  input: UserInviteInput
|};
export type RoleStaffInviteMutationResponse = {|
  +userInvite: ?{|
    +staff: {|
      +$fragmentRefs: EmployeesTable_staff_mutation$ref
    |}
  |}
|};
export type RoleStaffInviteMutation = {|
  variables: RoleStaffInviteMutationVariables,
  response: RoleStaffInviteMutationResponse,
|};
*/


/*
mutation RoleStaffInviteMutation(
  $input: UserInviteInput!
) {
  userInvite(input: $input) {
    staff {
      ...EmployeesTable_staff_mutation
      id
    }
  }
}

fragment EmployeesTable_staff_mutation on Staff {
  user {
    email
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleStaffInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserInvitePayload",
        "kind": "LinkedField",
        "name": "userInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Staff",
            "kind": "LinkedField",
            "name": "staff",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EmployeesTable_staff_mutation"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleStaffInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserInvitePayload",
        "kind": "LinkedField",
        "name": "userInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Staff",
            "kind": "LinkedField",
            "name": "staff",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b977f0b5c7ef34876725c68510a3bd16",
    "id": null,
    "metadata": {},
    "name": "RoleStaffInviteMutation",
    "operationKind": "mutation",
    "text": "mutation RoleStaffInviteMutation(\n  $input: UserInviteInput!\n) {\n  userInvite(input: $input) {\n    staff {\n      ...EmployeesTable_staff_mutation\n      id\n    }\n  }\n}\n\nfragment EmployeesTable_staff_mutation on Staff {\n  user {\n    email\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb13397d5b403c8e5a96daeb5d7ac45b';

module.exports = node;
