// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { usePaginationQuery } from 'react-ui/contexts/PaginationQueryContext'
import { SearchBar } from 'care-ui'

type PropsType = {
  fieldName?: string,
  fullWidth?: boolean,
  placeholder?: string,
}

export const PaginationSearchControl = ({
  placeholder,
  fieldName = 'search',
  fullWidth,
}: PropsType) => {
  const { variables, updateVariables } = usePaginationQuery()
  const { t: translation } = useTranslation()
  const [searchString, setSearchString] = useState<string>(
    variables[fieldName] || '',
  )

  // SEARCH FUNCTIONALITY
  const handleOnChange = (e: SyntheticInputEvent<>) => {
    setSearchString(e.target.value)
  }

  useEffect(() => {
    updateVariables({ [(fieldName: string)]: searchString })
  }, [searchString])

  const clearSearch = () => {
    setSearchString('')
    updateVariables({ [(fieldName: string)]: '' })
  }

  return (
    <SearchBar
      inputAttributes={{
        id: 'searchInput',
        onChange: handleOnChange,
        value: searchString,
        placeholder: placeholder || translation('search'),
      }}
      dataTestId="search-bar"
      ariaLabel="Search Bar"
      clearSearch={clearSearch}
      fullWidth={fullWidth}
    />
  )
}
