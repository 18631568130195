//  @flow

import React, { useEffect } from 'react'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { addMonths, subMonths } from 'date-fns'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import addNoDataModule from 'highcharts/modules/no-data-to-display'
import { filter, find, isEmpty, last } from 'lodash/fp'

import {
  type ActivityType,
  useScoreCardChart,
} from 'react-ui/contexts/ScoreCardChartContext/ScoreCardChartContext'
import { trackFitbitViewMoodGraph } from 'services/analytics/events'

import {
  type DateTimeAxisType,
  type formulaRangesType,
  Basic,
  chart,
  chartTitle,
  colorSelector,
  dateTimeAxis,
  yAxisLine,
} from './defaultOptions'
import { generateInvertedIndex, withinDateRange } from './helpers'
import {
  getPlotBands,
  getYAxis,
  graphTitle,
  plotLineOptions,
  secondaryGraphOptions,
  tooltipFormatter,
} from './secondaryGraphOptions'

addNoDataModule(Highcharts)

Highcharts.Tooltip.prototype.hide = () => {}

type PropsType = {
  chartType?: string,
  testId?: string,
}

type OptionPropsType = {
  activity?: ?ActivityType,
  additionalSeries?: Array<Object>,
  broadcastPointData?: Function,
  changeLogEntries?: Array<Object>,
  dateRange: DateTimeAxisType,
  hasAdditionalTrajectory?: boolean,
  isInversedRange?: boolean,
  mainLabel?: ?string,
  mainSeries: Array<Object>,
  scoresRange: Object,
  secondaryLabel?: ?string,
  setTrajectoriesIndex?: () => void,
  sortedAdditionalFormulas?: formulaRangesType,
  sortedFormulas: formulaRangesType,
  sortedFormulasRaw: formulaRangesType,
  trajectorySeries?: Array<Object>,
}

type seriesType = {
  dateRange: DateTimeAxisType,
  isAdditional?: boolean,
  line: Object,
}

const moodSeries = ({ line, dateRange, isAdditional }: seriesType) => {
  const { plotRawScore } = line

  const accessor = point => point.x

  const seriesYAxis = getYAxis({ plotRawScore, isAdditional })
  const seriesData = getPlotBands(line, isAdditional)

  const startDate = subMonths(dateRange.localStartDate, 6)
  const endDate = addMonths(dateRange.localEndDate, 6)

  return {
    name: line?.name,
    trajectoryData: line?.trajectoryData,
    isAdditional,
    plotRawScore,
    color: colorSelector[line.role],
    type: 'scatter',
    data: withinDateRange({
      series: seriesData,
      startDate,
      endDate,
      accessor,
    }),
    role: line?.role,
    lineWidth: line.name ? 2 : 0,
    yAxis: seriesYAxis,
    zIndex: 1,
    marker: {
      enabled: !!line.name,
      ...(isAdditional && {
        symbol: 'circle',
        lineColor: null,
        lineWidth: 2,
        fillColor: 'white',
      }),
    },
    showInLegend: !isAdditional,
    dashStyle: isAdditional ? 'dash' : 'solid',
  }
}

const predictionSeries = ({ line, dateRange, isAdditional }: seriesType) => {
  const endDate = addMonths(dateRange.localEndDate, 1)
  return {
    name: line.name,
    isTrajectory: true,
    referenceIndex: line.referenceIndex,
    color: '#F6F6F6',
    type: 'arearange',
    lineColor: '#E6E6E6',
    data: withinDateRange({
      series: line.data || [],
      startDate: dateRange.localStartDate,
      endDate,
    }),
    lineWidth: 2,
    yAxis: isAdditional ? 1 : 2,
    zIndex: -1,
    marker: {
      enabled: false,
    },
    states: {
      hover: {
        enabled: false,
      },
      inactive: {
        opacity: 1,
      },
    },
    showInLegend: false,
    dashStyle: 'dash',
    enableMouseTracking: false,
  }
}

const options = (
  {
    additionalSeries,
    broadcastPointData,
    changeLogEntries,
    dateRange,
    isInversedRange,
    mainLabel,
    mainSeries: series,
    scoresRange,
    secondaryLabel,
    sortedFormulas,
    sortedAdditionalFormulas,
    sortedFormulasRaw,
  }: OptionPropsType,
  translation: TFunction,
) => {
  if (!dateRange) return null

  const seriesSet = []
  const labelInverted = sortedFormulas && generateInvertedIndex(sortedFormulas)
  const { secondaryGraph, hasAdditionalGraph } = secondaryGraphOptions({
    secondaryLabel,
    sortedAdditionalFormulas,
  })

  if (series) {
    series.map(line => seriesSet.push(moodSeries({ line, dateRange })))
  }

  if (additionalSeries && sortedAdditionalFormulas && hasAdditionalGraph) {
    additionalSeries.map(line =>
      seriesSet.push(moodSeries({ line, dateRange, isAdditional: true })),
    )
  }

  if (seriesSet.length < 1) {
    seriesSet.push(moodSeries({ line: { name: '', data: [] }, dateRange }))
  }

  const trackableAxes = [
    {
      ...yAxisLine(scoresRange),
      title: {
        text: mainLabel && graphTitle({ label: mainLabel, isPrimary: true }),
        offset: 15,
        useHTML: true,
      },
      labels: {
        formatter() {
          return this.value === 1 ? translation('healthy') : null
        },
        style: {
          fontSize: '14px',
        },
      },
      crosshair: false,
    },
    secondaryGraph,
    {
      ...yAxisLine(sortedFormulasRaw),
      reversed: !isInversedRange,
      visible: false,
      crosshair: false,
    },
  ]

  return {
    ...chartTitle(),
    ...Basic(),

    chart: {
      ...chart,
      events: {
        load() {
          const { series: allInitialSeries } = this
          const individualSeries = filter(
            ({ userOptions }) => userOptions.role === 'INDIVIDUAL',
          )(allInitialSeries)
          const lastSeries = last(individualSeries)
          const { options: seriesOptions, index: seriesIndex } =
            lastSeries || {}
          const { trajectoryData, isAdditional } = seriesOptions || {}

          if (isEmpty(trajectoryData)) return
          const line = {
            name: translation('trajectory'),
            data: last(trajectoryData),
            referenceIndex: seriesIndex,
          }

          const newSeries = predictionSeries({
            line,
            dateRange,
            isAdditional,
          })
          this.addSeries(newSeries)
        },
        click() {
          const { tooltip } = this
          tooltip.update({ hide: true })
        },
      },
    },
    xAxis: {
      ...dateTimeAxis(dateRange),
      ...plotLineOptions(changeLogEntries),
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      series: {
        animation: false,
        stickyTracking: false,
        pointPlacement: 'on',
        events: {
          click: broadcastPointData,
        },
        point: {
          events: {
            mouseOver() {
              const {
                chart: thisChart,
                index: seriesIndex,
                color,
                options: mouseOverOptions,
              } = this.series
              const { trajectoryData } = mouseOverOptions
              const { index: pointIndex } = this
              const { series: allSeries } = thisChart

              const predictionGraph = find(
                predSeries =>
                  predSeries?.options.isTrajectory &&
                  predSeries?.options.referenceIndex === seriesIndex,
              )(allSeries)

              thisChart.tooltip.update({
                borderColor: color,
                shape: 'callout',
                style: {
                  pointerEvents: 'none',
                },
                positioner: undefined,
              })

              const data = trajectoryData?.[pointIndex]

              if (data?.length > 1 && predictionGraph) {
                predictionGraph.setData(data)
                this.isTrajectoryPoint = true
                this.color = null
                thisChart.tooltip.update({
                  shape: 'square',
                  borderColor: '#fff',
                  style: {
                    pointerEvents: 'none',
                  },
                  positioner() {
                    const { chart: { pointer, hoverPoint } } = this
                    const chartPosition = pointer.getChartPosition()

                    return {
                      x: hoverPoint.plotX + chartPosition.left - 330,
                      y: hoverPoint.plotY + chartPosition.top - 90,
                    }
                  },
                })
              }
            },
            mouseOut() {
              const {
                chart: thisChart,
                index: seriesIndex,
                options: mouseOutOptions,
              } = this.series
              const { trajectoryData } = mouseOutOptions
              const { series: allSeries } = thisChart

              const predictionGraph = find(
                predSeries =>
                  predSeries?.options.isTrajectory &&
                  predSeries?.options.referenceIndex === seriesIndex,
              )(allSeries)

              const data = last(trajectoryData)

              if (predictionGraph) {
                thisChart.tooltip.update({
                  style: {
                    pointerEvents: 'auto',
                  },
                })
                predictionGraph.setData(data)
              } else {
                thisChart.tooltip.update({
                  hide: true,
                })
              }
            },
          },
        },
      },
    },
    series: seriesSet,
    yAxis: trackableAxes,
    tooltip: {
      shared: false,
      useHTML: true,
      outside: true,
      padding: 14,
      style: {
        width: '332px',
      },
      formatter() {
        const { point, series: thisSeries } = this
        return tooltipFormatter({ point, series: thisSeries, labelInverted })
      },
    },
    legend: {
      itemStyle: {
        fontSize: '14px',
      },
    },
  }
}

/*
 * @prop {activity}: Receive the activity data
 * @prop {displayActivity}: Enable activity graph
 */
const LineChartBackCard = (props: PropsType) => {
  const { testId } = props
  const { t: translation } = useTranslation()
  const scoreCardProps = useScoreCardChart()
  const generatedOptions = options(scoreCardProps, translation)

  useEffect(() => {
    trackFitbitViewMoodGraph()
  }, [])

  return (
    <div data-testid={testId}>
      <HighchartsReact
        highcharts={Highcharts}
        options={generatedOptions}
        immutable
        constructorType="chart"
      />
    </div>
  )
}

export default LineChartBackCard
