// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { upperCase } from 'lodash'

import StaffIndividualSelectionList from 'components/Staff/StaffIndividualSelectionList'
import StaffLeadIndividualSelectionList from 'components/Staff/StaffLeadIndividualSelectionList'
import Copy from 'react-ui/assets/icons/copy-text.svg'
import escalatedIcon from 'react-ui/assets/icons/Icon material-warning.svg'
import { getValidClinicianFilters } from 'services/validValues'
import { RenderWithTooltip } from 'platform_web/components'
import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'
import usePersonalDetails from 'platform_web/hooks/usePersonalDetails'
import { usePolicies } from 'platform_web/hooks/usePolicies'
import { buildLocalizationKey } from 'platform_web/utility/buildLocalizationKey'
import { truncateText } from 'platform_web/utility/formatText'
import {
  Box,
  Button,
  Container,
  Divider,
  DropDownMenu,
  FlexContainer,
  FlexItem,
  Heading,
  Icon,
  Modal,
  Text,
} from 'care-ui'
import theme from 'care-ui/atoms'
import ArrowDown from 'care-ui/atoms/icons/arrow-down.svg'
import ChevronLeft from 'care-ui/atoms/icons/chevron-left.svg'
import useBreakpoints from 'care-ui/molecules/hooks/useBreakpoints'
import useModal from 'care-ui/molecules/hooks/useModal'

import {
  StaffDashboardSelectionClinician,
  StaffDashboardSelectionUser,
} from './query/StaffDashboardSelection'

import type { StaffDashboardSelection_clinician } from './query/__generated__/StaffDashboardSelection_clinician.graphql'
import type { StaffDashboardSelection_user } from './query/__generated__/StaffDashboardSelection_user.graphql'

export type StaffDashboardSelectionClinicianType = StaffDashboardSelection_clinician

type PropsType = {
  clinician: StaffDashboardSelection_clinician,
  user: StaffDashboardSelection_user,
}

const filtersToRender = ['assigned', 'clinician_active']

const StaffDashboardSelection = (props: PropsType) => {
  const { user, clinician } = props

  const {
    email,
    preferred_name,
    last_seen_at,
    personal_details: { date_of_birth, gender },
    linked_individual,
    stratified_scoring_result,
  } = user

  const personalDetailsPayload = usePersonalDetails()
  const {
    callbacks: {
      ageGenderDisplay,
      calculateAge,
      getLastSeenText,
      handleEmailClick,
    },
  } = personalDetailsPayload

  const { t: translation } = useTranslation()

  const { tenant } = clinician
  const { stratified_scoring } = tenant || {}
  const { escalated, initial_questionnaire_status } = linked_individual || {}
  const { status: stratifiedStatus } = stratified_scoring_result || {}

  const displayName = user.legal_name || user.username

  const { lg } = useBreakpoints()
  const { isOpen, closeModal, openModal } = useModal()

  const {
    tab_options,
    initial_state,
    current_role: { parent },
  } = clinician

  const policies = usePolicies()

  const clinicianView = policies?.CAN_VIEW_CLINICIAN_VIEWING_INDIVIDUAL_CONTENT

  const questionnaireStatus =
    stratified_scoring &&
    stratifiedStatus &&
    clinicianView &&
    initial_questionnaire_status === 'Completed'
      ? translation(buildLocalizationKey(stratifiedStatus))
      : translation(buildLocalizationKey(initial_questionnaire_status))

  useEffect(() => {
    if (lg) {
      closeModal()
      if (typeof window !== 'undefined' && document.body) {
        document.body.style.overflow = ''
      }
    }
  }, [lg])

  const filters = getValidClinicianFilters(tab_options, filtersToRender)

  const {
    hasLeadClinicianRole,
    isMultiRolesEnabled,
    roleType,
  } = useCurrentUserRoles()
  const renderStaffLeadIndividualSelectionList = isMultiRolesEnabled
    ? hasLeadClinicianRole
    : roleType === 'LEAD_CLINICIAN'

  const headingDisplay = (text) => {
    return (
      <RenderWithTooltip tooltipText={text} tooltipTestId="displayName">
        <Heading dataTestId="legalName" level={3}>
          {truncateText(text)}
        </Heading>
      </RenderWithTooltip>
    )
  }

  const emailDisplay = (text) => {
    return (
      <RenderWithTooltip tooltipText={text} tooltipTestId="email">
        <Text dataTestId="email" margin="xxs" bold>
          {truncateText(text)}
        </Text>
      </RenderWithTooltip>
    )
  }

  const initialScoreDisplay = (text) => {
    return (
      <RenderWithTooltip tooltipText={text} tooltipTestId="questionnaireStatus">
        <Text margin="xxs" dataTestId="initialScore" bold>
          {text ? truncateText(text) : '\u00A0'}
        </Text>
      </RenderWithTooltip>
    )
  }

  const genderDisplay = (text) => {
    return (
      <RenderWithTooltip tooltipText={text} tooltipTestId="gender">
        <Text margin="xxs" bold dataTestId="gender">
          {truncateText(text)}
        </Text>
      </RenderWithTooltip>
    )
  }

  return (
    <Box
      width="100%"
      position="sticky"
      top={63}
      zIndex={20}
      minHeight="98px"
      backgroundColor="default"
      alignContent="center"
      borderBottom="subtle"
    >
      <Container>
        <FlexContainer
          alignItems="center"
          gap="md"
          width="100%"
          justifyContent="space-between"
        >
          <FlexItem flex={2.5}>
            <FlexContainer alignItems="end" gap="xs">
              {headingDisplay(displayName)}

              {escalated && (
                <FlexItem>
                  <Icon
                    as={escalatedIcon}
                    color={theme.palette.text.danger}
                    dataTestId="escalatedIcon"
                  />
                </FlexItem>
              )}
            </FlexContainer>

            {preferred_name && (
              <Text dataTestId="preferredName" margin="xxs" bold>
                {`"${preferred_name}"`}
              </Text>
            )}
          </FlexItem>

          <FlexItem flex={1.5}>
            <Text size="sm">{upperCase(translation('last_active'))}</Text>

            <Text dataTestId="lastActive" margin="xxs" bold>
              {getLastSeenText(last_seen_at)}
            </Text>
          </FlexItem>

          {lg && (
            <FlexItem flex={2}>
              <Text size="sm">{upperCase(translation('email'))}</Text>

              <FlexContainer alignItems="center" gap="xxs">
                {emailDisplay(email)}
                <Icon
                  as={Copy}
                  scale={0.7}
                  color={theme.palette.text.positive}
                  onClick={() => handleEmailClick(email)}
                  dataTestId="copyIcon"
                />
              </FlexContainer>
            </FlexItem>
          )}

          <FlexItem flex={1.5}>
            <Text size="sm">{translation('initial_score')}</Text>
            {initialScoreDisplay(questionnaireStatus)}
          </FlexItem>

          {lg && (
            <FlexItem flex={1.5}>
              <Text size="sm">{translation('age_gender')}</Text>

              <FlexContainer gap="xxxs" wrap="nowrap">
                <FlexItem>
                  <Text margin="xxs" bold dataTestId="age">
                    {`${date_of_birth ? calculateAge(date_of_birth) : 'N/A'}`}
                  </Text>
                </FlexItem>

                <Text margin="xxs" bold dataTestId="age">
                  /
                </Text>

                {genderDisplay(gender)}
              </FlexContainer>
            </FlexItem>
          )}

          {!lg && (
            <FlexItem>
              <Button
                variant="secondary"
                onClick={openModal}
                dataTestId="moreInfo"
              >
                <Text>{translation('more_info')}</Text>
              </Button>

              <Modal
                width="540px"
                isOpen={isOpen}
                closeModal={closeModal}
                heading={translation('individual_info')}
                dataTestId="moreInfoModal"
              >
                <Box height="220px">
                  <FlexContainer
                    height="100%"
                    direction="row"
                    alignItems="start"
                  >
                    <FlexItem flex="100%">
                      <Text bold>{upperCase(translation('email'))}</Text>

                      <FlexContainer alignItems="center" gap="xxs">
                        <Text dataTestId="email" margin="xxs">
                          {email}
                        </Text>

                        <Icon
                          as={Copy}
                          scale={0.7}
                          color={theme.palette.text.positive}
                          onClick={() => handleEmailClick(email)}
                          dataTestId="copyIcon"
                        />
                      </FlexContainer>
                    </FlexItem>

                    <Divider />

                    <FlexItem flex="100%">
                      <Text bold margin="xxs">
                        {translation('age_gender')}
                      </Text>

                      <Text dataTestId="ageGender">
                        {ageGenderDisplay(date_of_birth, gender)}
                      </Text>
                    </FlexItem>

                    <Divider />
                  </FlexContainer>
                </Box>
              </Modal>
            </FlexItem>
          )}

          <FlexItem>
            <DropDownMenu
              position="end"
              dropDownTrigger={
                <Button
                  variant="secondary"
                  ariaLabel={translation('filter')}
                  dataTestId="filter"
                >
                  <FlexContainer
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    gap="xs"
                  >
                    <Text>{translation('switch_individual')}</Text>

                    <Icon as={ArrowDown} scale={0.5} />
                  </FlexContainer>
                </Button>
              }
            >
              <Box width="350px">
                <FlexContainer gap="xxs">
                  <Button
                    variant="text"
                    ariaLabel={translation('back_to_individual_list')}
                    dataTestId="back-to-individual-list"
                    to="staff_individuals"
                  >
                    <Icon as={ChevronLeft} />
                    {translation('back_to_individual_list')}
                  </Button>

                  <Divider />

                  <Box width="100%">
                    {renderStaffLeadIndividualSelectionList && (
                      <StaffLeadIndividualSelectionList parentId={parent?.id} />
                    )}

                    {!renderStaffLeadIndividualSelectionList && (
                      <StaffIndividualSelectionList
                        filters={filters}
                        queryType={initial_state}
                      />
                    )}
                  </Box>
                </FlexContainer>
              </Box>
            </DropDownMenu>
          </FlexItem>
        </FlexContainer>
      </Container>
    </Box>
  )
}

export default createFragmentContainer(StaffDashboardSelection, {
  user: StaffDashboardSelectionUser,
  clinician: StaffDashboardSelectionClinician,
})
