// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isValid } from 'date-fns'

import { localizedFormat } from 'shared/utils/DateFormatUtils'
import {
  Box,
  Button,
  DateRangePicker,
  DropDownMenu,
  DropDownMenuItem,
  FlexContainer,
  Icon,
  SimpleSelect,
  Text,
} from 'care-ui'
import ArrowDown from 'care-ui/atoms/icons/arrow-down.svg'

import useServiceMetricsDateRangeSelector from '../hooks/useServiceMetricsDateRangeSelector'

export type SelectionTypes =
  | 'last_thirty_days'
  | 'last_seven_days'
  | 'last_three_days'
  | 'custom'
  | string

type DateRangeType = {
  from: Date,
  to: Date,
}

type PropsType = {
  minDate?: Date,
  onChange: (value: DateRangeType) => void,
  onChangeType: (nextType: SelectionTypes) => void,
  selectedDateRange: DateRangeType,
  selectionType?: SelectionTypes,
}

const ServiceMetricsDateRangeSelector = (props: PropsType) => {
  const {
    minDate,
    onChange,
    onChangeType,
    selectedDateRange: { from, to },
    selectionType = 'last_three_days',
  } = props

  const setQueryVariables = (interval, dateRange) => {
    onChangeType(interval)
    onChange(dateRange)
  }

  const useServiceMetricsDateRangeSelectorPayload = useServiceMetricsDateRangeSelector(
    {
      interval: selectionType,
      from,
      to,
      setQueryVariables,
    },
  )

  const {
    closeDropDown,
    setCloseDropDown,
    isCustomInterval,
    toggleDropDownMenu,
    INTERVALS,
    changeInterval,
    range,
    setRange,
    handleOnCancel,
    updateCustomDateRange,
  } = useServiceMetricsDateRangeSelectorPayload
  const selectedIntervalOption =
    INTERVALS.find((item) => item.value === selectionType) || INTERVALS[1]

  const fromDate =
    range?.from && isValid(new Date(range.from))
      ? localizedFormat(new Date(range.from), 'd MMM yyyy')
      : ''

  const toDate =
    range?.to && isValid(new Date(range.to))
      ? localizedFormat(new Date(range.to), 'd MMM yyyy')
      : ''

  const disableApplyButton = !(fromDate && toDate)

  // Localization
  const { t: translation } = useTranslation()

  return (
    <>
      {!isCustomInterval && (
        <Box width="260px">
          <SimpleSelect
            options={INTERVALS}
            defaultOption={selectedIntervalOption}
            onChange={changeInterval}
            name="interval"
            dataTestIdForDropdown="intervalSelector"
            dataTestIdForDropdownOptions="intervalOptions"
          />
        </Box>
      )}

      {isCustomInterval && (
        <DropDownMenu
          position="end"
          closeDropDown={closeDropDown}
          setCloseDropDown={setCloseDropDown}
          dropDownTrigger={
            <Box width="260px">
              <Button
                variant="secondary"
                onClick={toggleDropDownMenu}
                dataTestId="customDateSelector"
                fullWidth
              >
                <Box width="100%">
                  <FlexContainer
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    gap="xxxs"
                  >
                    <Text size="lg">
                      {fromDate} - {toDate}
                    </Text>
                    <Icon as={ArrowDown} scale={0.5} />
                  </FlexContainer>
                </Box>
              </Button>
            </Box>
          }
        >
          <FlexContainer wrap="nowrap" gap="xxl">
            <FlexContainer direction="column" gap="xxxs">
              {INTERVALS.map((interval) => (
                <DropDownMenuItem
                  onClick={
                    interval.value === 'custom'
                      ? () => {}
                      : () => changeInterval(interval.value)
                  }
                  dataTestId="intervalOptions"
                  isActive={interval.value === 'custom'}
                >
                  {interval.label}
                </DropDownMenuItem>
              ))}
            </FlexContainer>

            <FlexContainer direction="column" alignItems="end">
              <DateRangePicker
                range={range}
                setRange={setRange}
                disableBeforeDate={minDate}
              />

              <FlexContainer gap="xs">
                <Button
                  variant="text"
                  onClick={handleOnCancel}
                  dataTestId="cancel"
                >
                  {translation('cancel')}
                </Button>

                <Button
                  variant="primary"
                  onClick={updateCustomDateRange}
                  disabled={disableApplyButton}
                  dataTestId="Apply"
                >
                  {translation('apply')}
                </Button>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </DropDownMenu>
      )}
    </>
  )
}

export default ServiceMetricsDateRangeSelector
