// @flow

import React, { useRef, useState } from 'react'
import { useFela } from 'react-fela'
import { useFragment } from 'react-relay'
import { useClickAway } from 'react-use'
import { get, getOr } from 'lodash/fp'

import { Icon } from 'care-ui'
import ArrowDown from 'care-ui/atoms/icons/arrow-down.svg'
import Close from 'care-ui/atoms/icons/close.svg'

import HealthCardMenuOptions from '../HealthCardMenuOptions'

import { menuStyle, triggerStyle } from './HealthCardMenu.style'
import { healthCardFragment } from './query/HealthCardMenu'

import type { HealthCardMenu_user_trackable$key } from './query/__generated__/HealthCardMenu_user_trackable.graphql'

type PropsType = {
  +user_trackable: HealthCardMenu_user_trackable$key,
}

const HealthCardMenu = ({ user_trackable: fragmentRef }: PropsType) => {
  const user_trackable = useFragment(healthCardFragment, fragmentRef)
  const ref = useRef(null)

  const [isOpen, setIsOpen] = useState(false)
  const { css } = useFela()

  const questionnaireId = getOr(
    null,
    'primary_user_questionnaire.questionnaire.id',
  )(user_trackable)
  const trackableId = get('trackable.id')(user_trackable)
  const userId = get('user.id')(user_trackable)

  const icon = isOpen ? Close : ArrowDown

  const handleClick = () => {
    setIsOpen((prev) => !prev)
  }

  useClickAway(ref, () => {
    setIsOpen(false)
  })

  return (
    <div ref={ref}>
      {isOpen && (
        <div className={css(menuStyle)}>
          <HealthCardMenuOptions
            questionnaireId={questionnaireId}
            trackableId={trackableId}
            userId={userId}
          />
        </div>
      )}

      <div
        className={css(triggerStyle)}
        onKeyPress={handleClick}
        onClick={handleClick}
        role="button"
        tabIndex="0"
        data-testid="downArrow"
      >
        <Icon as={icon} />
      </div>
    </div>
  )
}

export default HealthCardMenu
