// @flow

import { stringifyUrl } from 'query-string'

import { jsonSessionStorage } from 'services/browserStorage'
import { handleResponseFlash } from 'shared/services/flashes'
import i18n from 'platform_web/localization/index'

import { ResponseError } from './errors'

import { type ObjectType } from 'services/flow'

export type CallbacksType = {
  onError?: (error: ResponseError) => void,
  onSuccess?: (response: Response) => void,
}

type ValueType = ?string | ObjectType<?ValueType>
type BodyType = ObjectType<?ValueType>

export default function makeRequest(
  path: string,
  method: 'POST' | 'PATCH' | 'PUT' | 'GET' | 'DELETE',
  body: ?BodyType = null,
  cbs?: CallbacksType,
  queryParams?: ObjectType<?string>,
) {
  const controller = new AbortController()
  const { signal } = controller

  const timeout = setTimeout(() => {
    controller.abort()
  }, 5000)

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': jsonSessionStorage.getItem('csrfToken'),
    'X-Requested-With': 'XMLHttpRequest',
    'debug-app-url': window.location.href,
    'Accept-Language': i18n.language,
  }

  let fetchObject = {
    credentials: 'same-origin',
    headers,
    method,
  }

  if (body) {
    fetchObject = {
      ...fetchObject,
      body: JSON.stringify(body),
      signal,
    }
  }

  if (path.includes('?'))
    throw new Error('QueryString params must not be in the path!')

  const stringifedPath = stringifyUrl({ url: path, query: queryParams })

  return fetch(stringifedPath, fetchObject)
    .then((response) => {
      clearTimeout(timeout)
      handleResponseFlash(response)
      if (response.status >= 200 && response.status <= 399) {
        if (cbs && cbs.onSuccess) {
          cbs.onSuccess(response)
          return true
        }
      } else if (cbs && cbs.onError) {
        const error = new ResponseError(response, `${response.status} Error`)
        cbs.onError(error)
      }
      return false
    })
    .catch((error) => {
      clearTimeout(timeout)
      if (error.name === 'AbortError') {
        if (cbs && cbs.onError) {
          const errorMsg = new ResponseError(
            error,
            'The server took too long to respond. Please try again later.',
          )
          cbs.onError(errorMsg)
        }
      }
    })
}
