// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay'
import { uniqueId } from 'lodash'

import { FlexContainer, ScrollableBox, Text } from 'care-ui'

import ActivityLogCard from '../ActivityLogCard'

import { paginationFragment, query } from './query/ActivityLogCardContainer'

type PropsType = {
  userId: string,
}

const ActivityLogCardContainer = (props: PropsType) => {
  const { userId } = props

  const { node } = useLazyLoadQuery(query, { id: userId, count: 6 })
  const { data, loadNext, hasNext } =
    usePaginationFragment(paginationFragment, node) || {}
  const { edges } = data?.linked_individual?.activity_logs || {}

  const activityLogs = edges?.map(({ node: activityLogNode }) => (
    <ActivityLogCard
      key={uniqueId('recent-activity-')}
      activityLog={activityLogNode}
    />
  ))

  const activityLogEmpty = activityLogs?.length === 0
  const handleScroll = () => {
    if (hasNext) {
      loadNext(5)
    }
  }

  const { t: translation } = useTranslation()

  if (activityLogEmpty) {
    return (
      <FlexContainer justifyContent="center">
        <Text>{translation('no_recent_activity')}</Text>
      </FlexContainer>
    )
  }

  return (
    <ScrollableBox
      maxHeight="100%"
      onScroll={handleScroll}
      dataTestId="recentActivityContainer"
    >
      <FlexContainer direction="column" gap="sm">
        {activityLogs}
      </FlexContainer>
    </ScrollableBox>
  )
}

export default ActivityLogCardContainer
