// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { Icon } from 'care-ui'
import StarFilled from 'care-ui/atoms/icons/star-filled.svg'
import StarOutline from 'care-ui/atoms/icons/star-outline.svg'

import {
  bodyStyle,
  containerStyle,
  ratingStyle,
  scoreContainerStyle,
  titleStyle,
} from './HealthCardBody.style'

type PropsType = {
  lastUpdate: string,
  onClick: () => void,
  rating: string,
  selectable?: boolean,
  selected?: boolean,
  title: string,
  trend: string,
}

const HealthCardBody = (props: PropsType) => {
  const {
    lastUpdate,
    onClick,
    rating,
    selectable,
    selected,
    title,
    trend,
  } = props

  const { css } = useFela()

  const icon = selected ? StarFilled : StarOutline

  const withTrend = (
    <div className={css(containerStyle)}>
      <div className={css(ratingStyle)} data-testid="rating">
        {rating}
      </div>

      <div className={css(scoreContainerStyle)}>
        <div data-testid="changeStatus">{trend}</div>

        <div data-testid="timePeriod">{lastUpdate}</div>
      </div>
    </div>
  )

  const withoutTrend = (
    <div className={css(scoreContainerStyle)}>
      <div className={css(ratingStyle)} data-testid="rating">
        {rating}
      </div>

      <Icon as={icon} />
    </div>
  )

  return (
    <div
      className={css(bodyStyle)}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <div className={css(titleStyle)} data-testid="cardTitle">
        {title}
      </div>

      {selectable ? withoutTrend : withTrend}
    </div>
  )
}

export default HealthCardBody
