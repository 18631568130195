// @flow

import React from 'react'

import {
  getSelectedLanguage,
  isForbiddenUrl,
  LANGUAGES,
} from 'platform_web/utility/localizationUtil'
import { Box, SimpleSelect } from 'care-ui/index'

type PropsType = {
  handleLanguageChange?: (language: string) => void,
}

const LanguageSwitcher = (props: PropsType) => {
  const { handleLanguageChange } = props

  if (isForbiddenUrl) return null

  const handleChange = (language: string) => {
    localStorage.setItem('language', language)
    // Remove the 'lng' query parameter from the URL if it exists
    const url = new URL(window.location.href)
    url.searchParams.delete('lng')
    window.history.replaceState({}, '', url.toString())
    // Reload the page to apply the changes for BE translation
    window.location.reload()
  }

  return (
    <Box width="170px">
      <SimpleSelect
        options={LANGUAGES}
        defaultOption={getSelectedLanguage}
        onChange={handleLanguageChange || handleChange}
        name="interval"
        dataTestIdForDropdown="languageSwitcher"
        dataTestIdForDropdownOptions="languageOptions"
      />
    </Box>
  )
}

export default LanguageSwitcher
