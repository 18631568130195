// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { type FormikProps } from 'formik'
import useRouter from 'found/useRouter'

import Link from 'react-ui/components/Link/Link'
import { signInRequest } from 'shared/services/api/session'
import Sentry from 'shared/services/sentry'
import { Box, FlexContainer } from 'care-ui'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

import SignInForm from '../components/SignInForm'

const SignInPage = () => {
  const { router } = useRouter()

  const { t: translation } = useTranslation()

  function onSubmit(values, { setSubmitting, setStatus }: FormikProps) {
    setStatus()
    setSubmitting(true)
    signInRequest(values)
      .then(res => {
        setSubmitting(false)
        let nextLocation = res.headers.get('Location') || '/'
        if (nextLocation.includes('active_admin')) {
          nextLocation = '/'
        }
        setTimeout(() => {
          router.replace({
            name: 'signingIn',
            params: {
              nextLocation,
            },
          })
        }, 0)
        return res
      })
      .catch(error => {
        setSubmitting(false)
        if (error?.response?.name === 'AbortError') {
          setStatus({ error: error?.message })
        } else {
          switch (error.response.status) {
            case 401: {
              // todo: this error is awkward - refactor the res error
              // so that the json is already converted.
              // eg. { status, message } = res.error
              error.response
                .json()
                .then(body => {
                  setStatus({ error: body.error })
                  return body
                })
                .catch(err => {
                  setStatus({
                    error: translation(
                      'sorry_an_error_occurred_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_refresh_the_page_before_trying_to_login_again',
                    ),
                  })
                  Sentry.captureException(err)
                })
              break
            }
            default: {
              setStatus({
                error: translation(
                  'sorry_an_error_occurred_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_refresh_the_page_before_trying_to_login_again',
                ),
              })
              Sentry.captureException(error)
            }
          }
        }
      })
  }

  return (
    <Box height="500px" marginX="auto" marginY="auto">
      <FlexContainer
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <TextNew as="h1" typography="h1">
          {translation('welcome_to_the_innowell_platform')}
        </TextNew>

        <Box width="360px">
          <SignInForm onSubmit={onSubmit} />
        </Box>

        <Box marginY="xs">
          <Link to="/users/password/new">
            {translation('get_help_signing_in')}
          </Link>
        </Box>
      </FlexContainer>
    </Box>
  )
}

export default SignInPage
