// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { ButtonGroup } from 'react-ui/components/Button'
import Button from 'care-ui/molecules/Button/Button'

type PropsType = {
  label?: string,
  onToggleOpen: (event?: SyntheticEvent<*>) => any,
  styles?: { [className: string]: string },
}

const styleRules = () => ({
  footerContainer: {
    className: 'Search__footerContainer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '72px',
    borderTop: '2px solid #D0D6DE',
  },
})

const PopOverDoneFooterBase = ({
  styles = {},
  onToggleOpen,
  label,
}: PropsType) => {
  const { t: translation } = useTranslation()
  return (
    <div className={styles.footerContainer}>
      <ButtonGroup>
        <Button variant="primary" onClick={onToggleOpen}>
          {label || translation('done')}
        </Button>
      </ButtonGroup>
    </div>
  )
}


export default connect(styleRules)(PopOverDoneFooterBase)
