/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HelpNowModalBody_PublicQueryVariables = {||};
export type HelpNowModalBody_PublicQueryResponse = {|
  +viewer: {|
    +public_help_now_providers: ?$ReadOnlyArray<{|
      +chat_url: ?string,
      +description: ?string,
      +hours: ?string,
      +logo_url: ?string,
      +phone_number: ?string,
      +sequence: number,
      +slug: string,
      +sms: ?string,
      +title: string,
      +url: ?string,
      +id: string,
    |}>,
    +id: string,
  |}
|};
export type HelpNowModalBody_PublicQuery = {|
  variables: HelpNowModalBody_PublicQueryVariables,
  response: HelpNowModalBody_PublicQueryResponse,
|};
*/


/*
query HelpNowModalBody_PublicQuery {
  viewer {
    public_help_now_providers {
      chat_url
      description
      hours
      logo_url
      phone_number
      sequence
      slug
      sms
      title
      url
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PublicHelpNowProviderType",
        "kind": "LinkedField",
        "name": "public_help_now_providers",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "chat_url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hours",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo_url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone_number",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sequence",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sms",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HelpNowModalBody_PublicQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HelpNowModalBody_PublicQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5f9c4376efaa234b2651e8dfc2e84adc",
    "id": null,
    "metadata": {},
    "name": "HelpNowModalBody_PublicQuery",
    "operationKind": "query",
    "text": "query HelpNowModalBody_PublicQuery {\n  viewer {\n    public_help_now_providers {\n      chat_url\n      description\n      hours\n      logo_url\n      phone_number\n      sequence\n      slug\n      sms\n      title\n      url\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '286adbbd379edafe9859540b70b1ff01';

module.exports = node;
