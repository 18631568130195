// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { H2 } from 'react-ui/components/Heading'
import WizardSteps from 'react-ui/components/WizardSteps/WizardSteps'

import {
  type OnboardingWizardWrapperProps,
  showSteps,
} from './welcomePageHelper'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  input: OnboardingWizardWrapperProps,
}

const styleRules = ({ theme }) => ({
  title: {
    textAlign: 'left',
    color: theme.palette.input.default.base,
    marginBottom: '0',
  },
  subTitle: {
    color: theme.palette.input.default.base,
  },
  titleWrapper: {
    marginLeft: `calc(${theme.Grid.gutter} / 2)`,
  },
})

const UserOnboardingWizardWrapper = ({ input, styles, rules }: PropsType) => {
  const { t: translation } = useTranslation()
  const showStepsInput = {
    ...input,
  }
  return (
    <>
      <div className={styles.titleWrapper}>
        <H2 extend={rules.title}>Welcome {input.user.casual_name}</H2>
        <p className={styles.subTitle}>Hi there, we are glad you are here</p>
      </div>
      <WizardSteps {...showSteps(showStepsInput, translation)} />
    </>
  )
}
export default connect(styleRules)(UserOnboardingWizardWrapper)
